import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@peakon/bedrock/react/spinner';
import { Button } from '@peakon/components';

import * as CompanyActions from '../../../actions/CompanyActions';
import { showSuccessNotification } from '../../../actions/NotificationActions';
import api from '../../../client/peakonApi';
import asyncDispatch from '../../../utils/asyncDispatch';
import hasRequiredRight from '../../../utils/hasRequiredRight';
import RightsFilter from '../../RightsFilter';

import styles from './styles.css';

const PartnerConsultants = ({ company }) => {
  const dispatch = useDispatch();
  const { session, consultants, isLoadingConsultants } = useSelector(
    (state) => {
      return {
        session: state.session,
        consultants: state.company.consultants,
        isLoadingConsultants: state.company.isLoadingConsultants,
      };
    },
  );

  const { name, id } = company;

  useEffect(() => {
    const { rights } = session;

    if (hasRequiredRight(rights, ['backoffice:consultant:admin'])) {
      dispatch(CompanyActions.getConsultants(id, true));
    }
  }, [session, id, dispatch]);

  const handleConsultantRemove = (consultant) => {
    const {
      id: consultantId,
      attributes: { email },
    } = consultant;

    const confirmed = confirm(
      `Are you sure you want to revoke partner consultant access for ${email}?`,
    );

    if (!confirmed) {
      return;
    }

    dispatch(CompanyActions.removeConsultant(id, consultantId, true));
  };
  const sendPasswordReset = (consultant) => {
    const { email } = consultant.attributes;
    return asyncDispatch({
      dispatch,
      resource: 'EMPLOYEE_SEND_PASSWORD_RESET',
      action: api
        .post(`/auth/password/forgot`, null, {
          email,
          companyId: id,
        })
        .then((response) => {
          dispatch(
            showSuccessNotification({
              title: 'Password reset email will be sent.',
            }),
          );
          return response;
        }),
    });
  };
  const handleConsultantAdd = () => {
    const consultantEmail = prompt('Enter partner consultant email:');
    const consultantExpirationDate =
      prompt(
        'Enter expiration date (yyyy-mm-dd). Leave blank for unlimited.',
      ) || null;

    if (consultantEmail) {
      dispatch(
        CompanyActions.addPartnerConsultant(
          id,
          consultantEmail,
          consultantExpirationDate,
        ),
      );
    }
  };

  return (
    <RightsFilter rights={['backoffice:consultant:admin']}>
      <div className={styles.container}>
        <h2>Partner Consultants</h2>
        {isLoadingConsultants && (
          <div className={styles.spinner}>
            <Spinner size="48" />
          </div>
        )}
        {!isLoadingConsultants && consultants.length === 0 && (
          <p>{name} does not have any partner consultants.</p>
        )}
        {!isLoadingConsultants && consultants.length > 0 && (
          <table>
            <tbody>
              {consultants.map((consultant) => {
                const {
                  id: consultantId,
                  attributes: { email },
                } = consultant;

                return (
                  <tr key={consultantId}>
                    <td>{email}</td>
                    <td className={styles.button}>
                      <Button
                        type="danger"
                        size="small"
                        onClick={() => handleConsultantRemove(consultant)}
                      >
                        Remove
                      </Button>
                    </td>
                    <td className={styles.button}>
                      <Button
                        type="prymary"
                        size="small"
                        onClick={() => sendPasswordReset(consultant)}
                      >
                        Reset password
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div>
          <Button type="primary" size="small" onClick={handleConsultantAdd}>
            Add partner consultant
          </Button>
        </div>
      </div>
    </RightsFilter>
  );
};

PartnerConsultants.propTypes = {
  company: PropTypes.object,
};

export default PartnerConsultants;
